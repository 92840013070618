<template>
    <v-sheet></v-sheet>
</template>

<script>
    export default {
        created() {
            window.location = "https://research.m.works";
        }
    }
</script>